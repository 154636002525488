.latest-pop-up-button {
  height: 70px;
  width: 80px;
  position: absolute;
  right: 10%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  text-align: center;
  flex-direction: column;
  bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ffaf7a;
  color: white;
  transition: 0.24s cubic-bezier(0.4, 0, 0.2, 1);
}

.latest-pop-up-button-inner p {
  color: white;
  font-size: 14px;
}

.latest-pop-up-button-inner {
  height: 70px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.latest-pop-up-button i {
  font-size: 40px;
}

.latest-pop-up {
  width: 100%;
  overflow: hidden;
}

.latest-pop-up-wrapper {
  overflow: hidden;
  width:90%;
  height:90vh;
  margin-left:auto;
  margin-right:auto;
}

.latest-headline-container {
  position: relative;
  height: 140px;
}

.active-pop-up {
  height:870px;
}

.disabled-pop-up {
  height:0vh;
}

.latest-pop-up-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
}

.latest-pop-up-text {
  font-size:18px;
  padding: 0 40px;
  margin-bottom:50px;
  color:white;
  text-align: center;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}


@media screen and (min-width: 1000px) {
  .latest-pop-up-button:hover {
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  .latest-pop-up-button {
    height: 60px;
    width: 80px;
  }
  .latest-pop-up-button i {
    font-size: 30px;
  }

  .latest-pop-up-button p {
    font-size: 13px;
  }
  .latest-pop-up-button-inner {
    height: 60px;
    width: 80px;
    font-size: 8px;
  }
  .active-pop-up {
    height:600px;
  }
}

// for side pop up
// .latest-pop-up-button {
//     height: 120px;
//     width: 72px;
//     position: absolute;
//     left: 0;
//     display: flex;
//     padding-top: 20px;
//     align-items: center;
//     cursor: pointer;
//     justify-content: center;
//     text-align: center;
//     flex-direction: column;
//     top: 80%;
//     border-top-right-radius: 4px;
//     border-bottom-right-radius: 4px;
//     background-color: #ffaf7a;
//     color: white;
//     transition: 0.24s cubic-bezier(0.4, 0, 0.2, 1);
//   }

//   .latest-pop-up-button-inner p {
//     color: white;
//     font-size: 14px;
//   }

//   .latest-pop-up-button-inner {
//     height: 120px;
//     width: 80px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     flex-direction: column;
//   }

//   .latest-pop-up-button i {
//     font-size: 40px;
//   }

//   .latest-pop-up {
//     height: 100vh;
//     overflow: hidden;
//   }

//   .latest-pop-up-container {
//     position: fixed;
//     width: 100%;
//     overflow: hidden;
//     left: 0;
//     top: 0;
//     height: 100vh;
//   }

//   .rotate-180 {
//     -webkit-transform: rotate(180deg);
//     -moz-transform: rotate(180deg);
//     -ms-transform: rotate(180deg);
//     -o-transform: rotate(180deg);
//     transform: rotate(180deg);
//   }

//   @media screen and (min-width: 1000px) {
//     .latest-pop-up-button:hover {
//       width: 200px;
//     }
//   }

//   @media screen and (max-width: 600px) {
//     .latest-pop-up-button {
//       height: 100px;
//       width: 80px;
//       position: absolute;
//       left: 0;
//       display: flex;
//       align-items: center;
//       cursor: pointer;
//       justify-content: center;
//       text-align: center;
//       flex-direction: column;
//       top: 10%;
//       background-color: #ffaf7a;
//     }
//     .latest-pop-up-button-inner p {
//       color: white;
//       font-size: 12px;
//     }

//     .latest-pop-up-button-inner {
//       height: 100px;
//       width: 80px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       text-align: center;
//       flex-direction: column;
//       color: white;
//       font-size: 8px;
//     }
//   }
