@import "../../assets/colors";

.who-description {
  color: white;
  font-size: 18px;
  margin-top: 50px;
}

.guitar-video {
  border: none;
  outline: none;
  border-radius: 2px;
}

.who-social-media-icons-text {
  font-size:12px;
  color:white;
  margin-right:20px;
}

.who-social-media-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;

}

.who-social-media-icon {
  width:38px;
}


.who-project-headline {
  color: darken($default-blue, 10%);
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.who-project-title {
  color: darken($default-blue, 20%);
  font-size: 13px;
}

.who-projects {
  background-color: white;
  min-height: 500px;
  border-radius: 2px;
  margin: 160px 0 80px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
}

.who-orange-description {
  color: lighten(#ffaf7a, 3%);
}


.who-projects-videos-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.guitar-project + .guitar-project {
  margin-left: 30px;
}

.who-projects-inner {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 700px) {
  .guitar-project + .guitar-project {
    margin-left: 0;
    margin-top: 40px;
  }
  .who-projects-videos-container {
    width: 300px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1000px) {
  .who-projects {
    margin: 140px 0 50px 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 30px;
  }
  .who-social-media-icon {
    width:32px;
  }
}

@media screen and (max-width: 600px) {
  .who-projects {
    margin: 70px 0 0 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 30px;
  }
  .who-social-media-icon {
    width:28px;
  }
  .who-social-media-container {
    margin-top: 20px;
    margin-bottom:20px;
  
  }
}
