@import "../../assets/colors";

.scroll-meter-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  display: flex;
  align-items: center;
  background-color: rgb(255, 175, 122);
  transition:width 0.5s ease-out 0s;
}
.scroll-meter-inner {
  height: 5px;
  width: 0%;
  border-radius: 999px;
  border: solid;
  border-color: $default-blue;
  border-width: 0 1px 1px 0;
  mix-blend-mode: difference;
  background-color: $default-blue;
  transition: all 0.3s cubic-bezier(1, 0.8, 0.25, 1);
}
