* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

h1, h2, h3, h4, h5, p {
  font-family: 'Quicksand', sans-serif;
  font-weight:100;
  font-size:14px;
}

a, a:hover, a:visited {
  text-decoration: none;
  color: inherit;
}

body {
  overflow-x: hidden;
}