@import '../../assets/colors';

.toggle-button {
    position:absolute;
    bottom:0;
    right:50px;
    height:70px;
    width:100px;
    background-color: $orange;
}

.toggle-button-inner {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    text-align:center;
    flex-direction: column;
    height:100%;

}

.toggle-button p{
    color:white;
    font-size:13px;
}

.toggle-button i{
    color:white;
    font-size:30px;

}