@import "./assets/colors";

.app {
  background-color: $default-blue;
  width: 100%;
  min-height: 100vh;
  position:relative;
  overflow-x:hidden;
}

.headline {
  font-size: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  text-align: center;
}

.blue {
  color: darken($default-blue, 15%);
}

.back-blue {
  background-color: darken($default-blue, 10%);
}

.white {
  color: white;
}

.outer-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 100px 0;
}

.inner-container {
  margin: 0 50px;
  max-width: 840px;
  width: 90%;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-shadow {
  text-shadow: -1px 0 rgba(0, 0, 0, 0.01), 0 1px rgba(0, 0, 0, 0.01),
    1px 0 rgba(0, 0, 0, 0.01), 0 -1px rgba(0, 0, 0, 0.01);
}

.line-break-height {
  line-height: 5px;
  display: block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .line-break-height {
    line-height: 3px;
    display: block;
  }
  .mobile {
    display: block;
  }
  .headline {
    font-size: 26px;
    font-weight: 700;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
