.hire-headline {
    font-size:28px;
    margin-bottom: 30px;
    text-align: center;
}

.hire-text {
    font-size: 18px;
    text-align: center;
}

.hire-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height:200px;
  }