@import "../../assets/colors";

.portfolio-container {
  width: 100%;
  min-height: 1600px;
  max-width: 1220px;
  position: relative;
  margin: auto;
}

.project-container {
  background-color: white;
  padding: 20px;
  height: 420px;
  width: 300px;
  border-radius: 5px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12),
    inset 0 0 8px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-image {
  max-width: 250px;
  max-height: 180px;
  border-radius: 3px;
}

.project-title {
  font-size: 24px;
  margin-top: 20px;
  font-weight: 700;
}

.project-badge {
  box-shadow: inset 0px 0px 14px -2px rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 15px;
  top: 20px;
  border-radius: 50px;
  color: white;
  padding: 2px 15px;
  background-color: #ffaf7a;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-badge-years {
  box-shadow: inset 0px 0px 14px -2px rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 15px;
  color: white;
  top: 50px;
  font-size: 12px;
  border-radius: 50px;
  padding: 1px 7px;
  background-color: lighten($orange, 5%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-description {
  font-size: 17px;
  margin: 10px 10px 0 12px;
  white-space: pre-wrap;
}
.project-container:hover {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.25),
    inset 0 0 14px rgba(0, 0, 0, 0.22);
}

.project-header {
  font-size: 40px;
  color: white;
  width: 100%;
  margin-bottom: 50px;
  text-align: center;
}

.project-image-container {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .project-container {
    position: relative;
    top: 0 !important;
    left: 0 !important;
  }

  .portfolio-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  .project-container + .project-container {
    margin-top: 30px;
  }

  .project-title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1000px) {
  .small.project-container {
    padding: 10px 10px;
    height: 340px;
    width: 250px;
  }

  .small .project-badge-years {
    right: 15px;
    top: 45px;
    font-size: 11px;
    padding: 1px 4px;
  }

  .small .project-image {
    max-width: 180px;
    border-radius: 3px;
  }

  .small .project-title {
    font-size: 20px;
    margin-top: 14px;
  }

  .small .project-badge {
    right: 12px;
    top: 18px;
    padding: 1px 8px;
  }

  .small .project-description {
    font-size: 15px;
    padding: 0 10px;
    margin: 10px 0 0 0;
  }

  .small .project-image-container {
    width: 180px;
    height: 150px;
  }
}

@media screen and (max-width: 600px) {
  .project-container {
    padding: 20px;
    height: 350px;
    width: 240px;
  }

  .project-badge-years {
    right: 15px;
    top: 45px;
    font-size: 11px;
    padding: 1px 4px;
  }

  .project-image {
    max-width: 150px;
    border-radius: 3px;
  }

  .project-title {
    font-size: 18px;
    margin-top: 14px;
  }

  .project-badge {
    right: 10px;
    top: 14px;
    padding: 3px 10px;
  }

  .project-description {
    font-size: 15px;
    margin: 10px 0 0 0;
  }

  .project-image-container {
    width: 150px;
    height: 150px;
  }
}
