.welcome-text {
  font-size: 45px;
  color: white;
  font-weight: 500;
  margin-top:50px;
  text-align: center;
}

.welcome-trivia {
  font-size: 26px;
  color: white;
  height: 100px;
  margin-bottom: -100px;
  text-align: center;
  padding: 10px 30px;
}

.welcome-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.welcome-picture {
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 350px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.12), 0px 0px 10px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.welcome-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 700px;
  margin: auto;
  height: 1000px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.test {
  color: green;
}

.social-media-container {
  margin-top: 15px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.social-media-icon {
  width: 25px;
}

.social-media-link {
  display:flex;
  flex-direction: row;
  align-items: flex-end;
}

.social-media-link + .social-media-link {
  margin-left: 15px;
}

@media screen and (max-width: 600px) {
  .welcome-text {
    font-size: 25px;
    color: white;
  }

  .welcome-trivia {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }
  .welcome-picture {
    width: 160px;
  }
}

@media screen and (max-width: 1000px) {
  .welcome-text {
    font-size: 26px;
    color: white;
    font-weight: 700;
  }

  .welcome-trivia {
    font-size: 19px;
    margin-top: 20px;
    text-align: center;
  }
  .welcome-picture {
    width: 160px;
  }
  .social-media-container {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .social-media-icon {
    width: 18px;
  }

  .social-media-link + .social-media-link {
    margin-left: 20px;
  }
}
