@import '../../assets/colors';

.me-container {
    background-color: white;
    display:flex;
    justify-content: space-evenly;
}

.outer-slider-box {
    background-color: white;
    border: 2px solid darken($default-blue, 5%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    height:10px;
}

.inner-slider-box {
    background-color: darken($default-blue, 5%);
    border-radius: 50px;
    transition: 5s cubic-bezier(0.4, 0, 0.2, 1);
    height:10px;
}

.named-slider-text {
    color:$orange;

}

.box-sliders-container {
    display:flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1000px;
    padding:10px 30px;
    margin:auto;
}

.named-box-slider-container {
    margin-top: 30px;
    width:100%;
    
}
