.random-content-nav-tab {
    width:150px;
    display:flex;
    height:100%;
    justify-content: center;
    align-items: center;
}

.random-content-nav {
    width:100%;
    height:100px;
    background-color: red;
}

.random-content-container {
    height:100vh;
    width:100%;
}

